import { ValueFormatterParams } from 'ag-grid-community';
import { isArray, keyBy } from 'lodash';

import { ItemVarietalOrVintages, SelectOptions } from '@etoh/database/core';
import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPercent,
} from 'apps/desktop/src/app/locales/locales';

function formatter(
  params: ValueFormatterParams,
  fn: (value: any) => any
): string | null {
  const value = params.value;
  if (!value) {
    return null;
  }

  if (isArray(value)) {
    return value.map(fn).join('<br>');
  }

  return fn(value);
}

export function dateFormatter(params: ValueFormatterParams): any {
  return formatDate(params.value, 'shortDate');
}

export function numberFormatter(
  params: ValueFormatterParams,
  suffix: string | null = null
): null | string {
  if (params.value === null || params.value === undefined) {
    return null;
  }

  const formattedNumber = formatNumber(params.value);
  if (formattedNumber === null) {
    return null;
  }

  if (suffix) {
    return `${formattedNumber} ${suffix}`;
  }

  return formattedNumber;
}

export function plainTextFromFormatter(
  params: ValueFormatterParams
): null | string {
  if (params.value === null || params.value === undefined) {
    return null;
  }
  return getPlainTextFromHtml(params.value as string);
}

export function percentFormatter(params: ValueFormatterParams): null | string {
  const res = params.value;
  if (res === null || res === undefined) {
    return res;
  }

  const formatedPercent = formatPercent(parseFloat(res) * 0.01);

  return formatedPercent;
}

export function currencyFormatter(
  params: ValueFormatterParams,
  currency: string,
  digitsInfo: string | undefined = undefined
) {
  const value = params.value;
  if (value === null || value === undefined) {
    return null;
  }

  return formatCurrency(value, undefined, currency, digitsInfo);
}

export function dbFormatter(
  params: ValueFormatterParams,
  dictionnary: { [keyuid: string]: any },
  fn: (value: any) => any
): string | null {
  return formatter(params, (value) => value);
}

export function getPercentage(p: number) {
  if (p === null || p === undefined) {
    return null;
  }

  return formatPercent(p * 0.01);
}

export function getDisplayForVariatealOrVintages(v: ItemVarietalOrVintages) {
  return [v?.type, getPercentage(v?.percentage)].filter((r) => r).join(' ');
}

export function getDisplaysForVariatealOrVintages(
  varietalsOrVintages: ItemVarietalOrVintages[]
) {
  return (varietalsOrVintages || [])
    .filter((a) => a?.type !== null)
    .map((a: any) => getDisplayForVariatealOrVintages(a))
    .join(', ');
}

const optionsByKeys: any = {};

export function getValueForOptions(
  type: string,
  options: SelectOptions[],
  value: any
) {
  if (!optionsByKeys[type]) {
    optionsByKeys[type] = keyBy(options, 'value');
  }

  const optionsByKeysByType = optionsByKeys[type];

  return optionsByKeysByType[value]?.label;
}

export function getPlainTextFromHtml(
  text: string | null | undefined
): string | null {
  if (!text) {
    return null;
  }

  text = text.replace(/<br>/g, '\n');

  text = text.replace(/&nbsp;/g, ' ');
  text = text.replace(/&amp;/g, '&');

  return text.replace(/<[^>]*>/g, '');
}

export function plainTextToHTML(text: string | any): string | null {
  if (!text) {
    return null;
  }

  return (text as string).replace(/(\r\n|\n|\r)/gm, '<br>');
}
